// import { axios } from 'axios';
import { createSlice } from '@reduxjs/toolkit';
// utils
import customAxios from '../../utils/axios';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  rolesList: [],
  operatorData: [],
  projectData: [],
  clientsList: [],
  currentPage: 1,
  totalPages: 1,
  totalResults: 10,
  pageSize: 10,
  projectCurrentPage: null,
  projectTotalPages: null,
  projectTotalResults: null,
  clientTrades: {},
  scriptwiseTrades: {},
  activityLogs: {},
  currentScriptClientsTrade: {},
  operatorProfile: {},
  adminProfile: {},
  brokerProfile: {},
  clientProfile: {},
  projectProfile: {},
  userbalanceSummary: {},
  userMoneyDetails: {},
  userFileDetails: {},
  riskyClients: {},
  weeklyPLofUsers: [],
  banScripts: [],
  superAdminBanScripts: [],
  broadCastMessage: {},
  flashMessages: {},
};

const slice = createSlice({
  name: 'operator',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CLIENT TRADES
    getClientTradesSuccess(state, action) {
      state.isLoading = false;
      state.clientTrades = action.payload;
    },
    getScriptWiseTradeSuccess(state, action) {
      state.isLoading = false;
      state.scriptwiseTrades = action.payload;
    },
    getcurrentScriptClientTradeSuccess(state, action) {
      state.isLoading = false;
      state.currentScriptClientsTrade = action.payload;
    },
    // GET ROLESLIST
    getRoleListSuccess(state, action) {
      state.isLoading = false;
      state.rolesList = action?.payload;
    },

    getRiskyClientsListSuccess(state, action) {
      state.isLoading = false;
      state.riskyClients = action?.payload;
    },

    getWeeklyPLofUsers(state, action) {
      state.isLoading = false;
      state.weeklyPLofUsers = action?.payload;
    },

    broadCastMessageOperations(state, action) {
      state.isLoading = false;
      state.broadCastMessage = action?.payload;
    },

    getFlashMessages(state, action) {
      state.isLoading = false;
      state.flashMessages = action?.payload;
    },

    getBanScripts(state, action) {
      state.isLoading = false;
      state.banScripts = action?.payload;
    },

    getSuperAdminBanScripts(state, action) {
      state.isLoading = false;
      state.superAdminBanScripts = action?.payload;
    },

    // GET ADMIN
    getAdminProfileSuccess(state, action) {
      state.isLoading = false;
      state.adminProfile = action?.payload?.user;
    },

    // GET BROKER
    getBrokerProfileSuccess(state, action) {
      state.isLoading = false;
      state.brokerProfile = action?.payload?.user;
    },
    // GET CLIENT
    getClientProfileSuccess(state, action) {
      state.isLoading = false;
      state.clientProfile = action?.payload?.user;
    },
    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action?.payload?.docs;
      state.totalPages = action?.payload?.totalPages;
      state.currentPage = action?.payload?.page;
      state.totalResults = action?.payload?.totalResults;
    },

    // GET OPERATORS
    getOperatorListSuccess(state, action) {
      state.isLoading = false;
      state.operatorData = action?.payload?.docs;
      state.totalPages = action?.payload?.totalPages;
      state.currentPage = action?.payload?.page;
      state.totalResults = action?.payload?.totalDocs;
      state.pageSize = action?.payload?.limit;
    },
    getClientsListSuccess(state, action) {
      state.isLoading = false;
      state.clientsList = action?.payload?.docs;
      state.totalPages = action?.payload?.totalPages;
      state.currentPage = action?.payload?.page;
      state.totalResults = action?.payload?.totalDocs;
      state.pageSize = action?.payload?.limit;
    },

    getUserBalanceSummarySuccess(state, action) {
      state.isLoading = false;
      state.userbalanceSummary = action?.payload;
    },
    getUserMoneyDetailSuccess(state, action) {
      state.isLoading = false;
      state.userMoneyDetails = action?.payload;
    },

    getUserFilesSuccess(state, action) {
      state.isLoading = false;
      state.userFileDetails = action?.payload;
    },

    // POST OPERATOR
    postOperatorSuccess(state) {
      state.isLoading = false;
    },

    // PUT OPERATOR
    updateOperatorListSuccess(state, action) {
      state.isLoading = false;
      const { operatorID, filterStatus, newStatus } = action.payload;
      if (filterStatus === 'all') {
        const findIndex = state.operatorData.findIndex((data) => data._id === operatorID);
        state.operatorData[findIndex].status = newStatus;
      } else {
        state.operatorData = state.operatorData.filter((data) => data._id !== operatorID);
      }
    },

    // GET OPERATOR PROFILE
    getOperatorProfileSuccess(state, action) {
      if (action?.payload?.loading) {
        state.isLoading = false;
      }
      state.operatorProfile = action?.payload?.user;
    },

    getActivityLogsSuccess(state, action) {
      state.isLoading = false;
      state.activityLogs = action.payload;
    },

    // GET OPERATOR PROJECT LIST

    getOperatorProjectListSuccess(state, action) {
      state.isLoading = false;
      state.projectData = action?.payload?.projects;
      state.projectProfile = {};
      state.totalPages = action?.payload?.totalPages;
      state.currentPage = action?.payload?.page;
      state.totalResults = action?.payload?.totalResults;
    },

    // PUT OPERATOR PROJECT
    updateOperatorProjectListSuccess(state, action) {
      state.isLoading = false;
      const { projectID, filterStatus, newStatus } = action.payload;
      if (filterStatus === 'all') {
        const findIndex = state.projectData.findIndex((data) => data._id === projectID);
        state.projectData[findIndex].status = newStatus;
      } else {
        state.projectData = state.projectData.filter((data) => data._id !== projectID);
      }
    },

    // GET OPERATOR PROJECT PROFILE
    getOperatorProjectProfileSuccess(state, action) {
      state.isLoading = false;
      state.projectProfile = action?.payload?.project;
    },

    // GET PROJECT DETAILS BY ID
    getProjectByIDSuccess(state, action) {
      state.isLoading = false;
      state.projectData = action?.payload?.projects;
      state.projectTotalPages = action?.payload?.totalPages;
      state.projectCurrentPage = action?.payload?.page;
      state.projectTotalResults = action?.payload?.totalResults;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const operatorActions = slice.actions;

// ----------------------------------------------------------------------

// GET CLIENTS TRADER
// export function getClientsTrade(pageSize, pageNumber, sortBy, sortOrder, searchWord, type) {
//   return async () => {
//     let response;
//     try {
//       dispatch(slice.actions.startLoading());

//       const queryParams = new URLSearchParams({
//         pageSize,
//         page: pageNumber,
//         sortOrder,
//         sortBy,
//       });

//       if (searchWord?.length > 0) {
//         queryParams.append('search', searchWord);
//       }
//       if (type.length > 0) {
//         queryParams.append('type', type);
//       }

//       const url = `/user/clients/trades?${queryParams.toString()}`;

//       response = await customAxios.get(url);

//       dispatch(slice.actions.getClientTradesSuccess(response?.data?.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error?.response?.data));
//       // if (customAxios.isCancel(error)) {
//       //   throw error?.response?.data;
//       // }
//     }
//   };
// }

export function getClientsTrade(pageSize, pageNumber, sortBy, sortOrder, filterName, type) {
  return async (dispatch) => {
    let response;
    try {
      dispatch(slice.actions.startLoading());

      // Initialize query parameters
      const queryParams = new URLSearchParams({
        pageSize,
        page: pageNumber,
        sortOrder,
        sortBy,
      });

      // Append searchWord if it exists
      if (filterName?.tradingSymbol) {
        queryParams.append('tradingSymbol', filterName.tradingSymbol);
      }

      if (filterName?.name) {
        queryParams.append('name', filterName.name);
      }

      // Append segments individually
      if (filterName?.segments?.length > 0) {
        filterName.segments.forEach((segment) => {
          queryParams.append('segements', segment);
        });
      }

      // Append statuses individually
      if (filterName?.statuses?.length > 0) {
        filterName.statuses.forEach((status) => {
          queryParams.append('statuses', status);
        });
      }

      // Append boughtAt and soldAt if they exist
      if (filterName?.boughtAt) {
        queryParams.append('boughtAt', filterName.boughtAt);
      }

      if (filterName?.soldAt) {
        queryParams.append('soldAt', filterName.soldAt);
      }

      // Append type if it exists
      if (type?.length > 0) {
        queryParams.append('type', type);
      }

      // Generate the final URL with query params
      const url = `/user/clients/trades?${queryParams.toString()}`;

      // Perform the request
      response = await customAxios.get(url);

      // Dispatch the success action with the response data
      dispatch(slice.actions.getClientTradesSuccess(response?.data?.data));
    } catch (error) {
      // Handle errors
      dispatch(slice.actions.hasError(error?.response?.data));
    }
  };
}

export function getWeeklyPLofUsers({ selectedRole, isoWeekCode, year }) {
  return async () => {
    let response;
    try {
      const queryParams = new URLSearchParams({
        role: selectedRole,
        week: isoWeekCode,
        year,
      });
      await dispatch(slice.actions.startLoading());
      const url = `/weekly-report/profits?${queryParams}`;
      response = await customAxios.get(url);
      await dispatch(slice.actions.getWeeklyPLofUsers(response?.data));
    } catch (error) {
      await dispatch(slice.actions.hasError(error?.response?.data));
    }
  };
}

// export function getClientLineTrades(pageSize, pageNumber, sortBy, sortOrder, searchWord, timeDifference = 5) {
//   return async () => {
//     let response;
//     try {
//       dispatch(slice.actions.startLoading());
//       const queryParams = new URLSearchParams({
//         pageSize,
//         page: pageNumber,
//         sortOrder,
//         sortBy,
//         timeDifference,
//       });

//       if (searchWord?.length > 0) {
//         queryParams.append('search', searchWord);
//       }

//       const url = `/user/clients/line-trades?${queryParams.toString()}`;

//       response = await customAxios.get(url);

//       dispatch(slice.actions.getClientTradesSuccess(response?.data?.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error?.response?.data));
//       // if (customAxios.isCancel(error)) {
//       //   throw error?.response?.data;
//       // }
//     }
//   };
// }
export function getClientLineTrades(pageSize, pageNumber, sortBy, sortOrder, searchFilters = {}, timeDifference = 5) {
  return async (dispatch) => {
    let response;
    try {
      dispatch(slice.actions.startLoading());

      // Build URLSearchParams with pagination and sorting
      const queryParams = new URLSearchParams({
        pageSize,
        page: pageNumber,
        sortOrder,
        sortBy,
        timeDifference,
      });

      // Check if searchFilters object has values and append them to queryParams
      if (searchFilters?.tradingSymbol) {
        queryParams.append('tradingSymbol', searchFilters.tradingSymbol);
      }
      if (searchFilters?.name) {
        queryParams.append('name', searchFilters.name);
      }
      if (searchFilters?.boughtAt) {
        queryParams.append('boughtAt', searchFilters.boughtAt);
      }
      if (searchFilters?.soldAt) {
        queryParams.append('soldAt', searchFilters.soldAt);
      }
      if (searchFilters?.timeDuration) {
        queryParams.append('timeDuration', searchFilters.timeDuration);
      }
      // Append segments as individual query params
      if (searchFilters?.segments?.length > 0) {
        searchFilters.segments.forEach((segment) => {
          queryParams.append('segements', segment);
        });
      }

      const url = `/user/clients/line-trades?${queryParams.toString()}`;

      response = await customAxios.get(url);

      dispatch(slice.actions.getClientTradesSuccess(response?.data?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error?.response?.data));
    }
  };
}

// UPDATE USER BALANCE
export function updateUserBalance(userId, amount, operation) {
  return async () => {
    await dispatch(slice.actions.startLoading());
    try {
      const response = await customAxios.put(`/user/balance/update`, { userId, amount, operation });
      await dispatch(slice.actions.stopLoading());
      return response.data;
    } catch (err) {
      dispatch(slice.actions.hasError(err));
      console.log('ERRROR', err);
      throw err;
    }
  };
}

// Thunk to get all ban scripts by super admin
export function getAllBanScripts() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await customAxios.get('/script/all-ban-list');
      dispatch(slice.actions.getSuperAdminBanScripts(response.data.banScripts));
    } catch (error) {
      dispatch(slice.actions.hasError(error.response.data));
    }
  };
}

// Thunk to set a ban script
export function setBanScript(banScripts) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await customAxios.post('/script/set-ban', { banScripts });
      dispatch(slice.actions.stopLoading());
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error.response.data));
      throw error;
    }
  };
}

export function broadCastMessageOperations(payload, isEdit, messageId, flash) {
  return async () => {
    let response;
    try {
      await dispatch(slice.actions.startLoading());

      if (isEdit && messageId) {
        await customAxios.put(`/message/${messageId}`, payload);
        response = await customAxios.get(`/message`);
      } else if (payload) {
        await customAxios.post(`/message`, payload);
        response = await customAxios.get(`/message`);
      } else if (messageId) {
        await customAxios.delete(`/message/${messageId}`);
        response = await customAxios.get(`/message`);
      } else if (flash) {
        response = await customAxios.get(`/message?flash=true`);
      } else {
        response = await customAxios.get(`/message`);
      }

      await dispatch(slice.actions.broadCastMessageOperations(response?.data));
    } catch (error) {
      await dispatch(slice.actions.hasError(error?.response?.data));
    }
  };
}

export function getFlashMessages() {
  return async () => {
    let response;
    try {
      await dispatch(slice.actions.startLoading());

      response = await customAxios.get(`/message?flash=true`);

      await dispatch(slice.actions.getFlashMessages(response?.data));
    } catch (error) {
      await dispatch(slice.actions.hasError(error?.response?.data));
    }
  };
}

export function getBanScripts() {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(slice.actions.startLoading());

      // // Axios instance with custom headers
      // const apiClient = axios.create({
      //   baseURL: 'https://webapi.niftytrader.in/webapi',
      //   timeout: 10000,
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Accept: 'application/json, text/plain, */*',
      //     'Accept-Encoding': 'gzip, deflate, br, zstd',
      //     'User-Agent':
      //       'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/131.0.0.0 Safari/537.36',
      //     platform_type: 1,
      //     Origin: 'https://www.niftytrader.in',
      //     Referer: 'https://www.niftytrader.in/',
      //     Connection: 'keep-alive',
      //     'sec-ch-ua': '"Google Chrome";v="131", "Chromium";v="131", "Not_A Brand";v="24"',
      //     'sec-ch-ua-mobile': '?0',
      //     'sec-ch-ua-platform': '"Windows"',
      //     'Sec-Fetch-Site': 'same-site',
      //     'Sec-Fetch-Mode': 'cors',
      //     'Sec-Fetch-Dest': 'empty',
      //   },
      // });

      // API call
      const response = await customAxios.get('/script/ban-list');

      // Dispatch success action with response data
      dispatch(slice.actions.getBanScripts(response?.data));
    } catch (error) {
      // Dispatch error action
      dispatch(slice.actions.hasError(error?.response?.data));
    }
  };
}

// GET OPERATOR DETAILS
export function getOperator(managerId, pageSize, pageNumber, statusData, order, searchWord, cancelToken) {
  return async () => {
    let response;
    try {
      await dispatch(slice.actions.startLoading());
      if (searchWord?.length > 0) {
        response = await customAxios.get(
          `/user?managerId=${managerId}&status=${statusData}&order=${order}&pageSize=${pageSize}&page=${pageNumber}&search=${searchWord}`,
          { cancelToken: cancelToken.token }
        );
      } else {
        // response = await customAxios.get(
        //   `/user?managerId=${managerId}&status=${statusData}&order=${order}&pageSize=${pageSize}&page=${pageNumber}`
        // );
        response = await customAxios.get(`/user?managerId=${managerId}`);
      }
      // const { message } = response?.data;
      await dispatch(slice.actions.getOperatorListSuccess(response?.data));
    } catch (error) {
      // await dispatch(slice.actions.hasError(error?.response?.data));
    }
  };
}
export function getClients({ userId, pageSize = 10, pageNumber = 1, sortBy, sortOrder, searchWord }) {
  return async () => {
    let response;
    try {
      await dispatch(slice.actions.startLoading());
      const queryParams = new URLSearchParams({
        pageSize,
        page: pageNumber,
        sortOrder,
        sortBy,
      });
      if (searchWord?.length > 0) {
        queryParams.append('search', searchWord);
      }
      if (userId) {
        queryParams.append('userId', userId);
      }
      const url = `/user/clients/all?${queryParams.toString()}`;
      response = await customAxios.get(url);
      // if (searchWord?.length > 0) {
      //   response = await customAxios.get(
      //     `/user/clients/all?status=${statusData}&order=${order}&pageSize=${pageSize}&page=${pageNumber}&searchTerm=${searchWord}`
      //   );
      // } else {
      //   // response = await customAxios.get(
      //   //   `/user?managerId=${managerId}&status=${statusData}&order=${order}&pageSize=${pageSize}&page=${pageNumber}`
      //   // );
      //   response = await customAxios.get(
      //     `/user/clients/all?status=${statusData}&order=${order}&pageSize=${pageSize}&page=${pageNumber}&searchTerm=${searchWord}`
      //   );
      // }
      // const { message } = response?.data;
      await dispatch(slice.actions.getClientsListSuccess(response?.data?.data));
    } catch (error) {
      // await dispatch(slice.actions.hasError(error?.response?.data));
    }
  };
}

export function getRiskyClients({
  pageSize = 10,
  pageNumber = 1,
  sortBy,
  sortOrder,
  searchWord,
  balancePercentage = 20,
}) {
  return async () => {
    let response;
    try {
      await dispatch(slice.actions.startLoading());
      const queryParams = new URLSearchParams({
        pageSize,
        page: pageNumber,
        sortOrder,
        sortBy,
        balancePercentage,
      });
      if (searchWord?.length > 0) {
        queryParams.append('search', searchWord);
      }
      const url = `/user/risky-clients/all?${queryParams.toString()}`;
      response = await customAxios.get(url);
      await dispatch(slice.actions.getRiskyClientsListSuccess(response?.data?.data));
    } catch (error) {
      // await dispatch(slice.actions.hasError(error?.response?.data));
    }
  };
}

// PUT OPERATORS
export function putOperator(operatorID, statusTab, notes, filterStatus) {
  return async () => {
    await dispatch(slice.actions.startLoading());
    try {
      const response = await customAxios.put(`/admin/user/${operatorID}?${statusTab}=true`);
      const { status, newStatus, message } = response?.data;
      if (status === 200) {
        await dispatch(slice.actions.updateOperatorListSuccess({ operatorID, statusTab, filterStatus, newStatus }));
      } else {
        await dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
    }
  };
}

export function getActivityLogs({
  pageSize,
  page,
  type,
  sortOrder,
  sortBy,
  description,
  createdBy,
  forUser,
  startDate,
  endDate,
  userRoles,
  statuses,
}) {
  return async (dispatch) => {
    await dispatch(slice.actions.startLoading());
    try {
      const queryParamsObj = new URLSearchParams({
        pageSize,
        page,
        sortOrder,
        sortBy,
        type,
      });

      // Conditionally add optional fields if they are defined
      if (description !== undefined) queryParamsObj.set('description', description);
      if (createdBy !== undefined) queryParamsObj.set('createdBy', createdBy);
      if (forUser !== undefined) queryParamsObj.set('forUser', forUser);
      if (userRoles !== undefined) queryParamsObj.set('userRoles', userRoles);
      if (statuses !== undefined) queryParamsObj.set('statuses', statuses);

      // Handle startDate and endDate conditions
      if (
        startDate !== undefined &&
        endDate !== undefined &&
        !(startDate === '1970-01-01' && endDate === '1970-01-01')
      ) {
        queryParamsObj.set('startDate', startDate);
        queryParamsObj.set('endDate', endDate);
      }
      // Prepare query parameters

      const response = await customAxios.get(`/userlog/all?${queryParamsObj.toString()}`);
      dispatch(operatorActions.getActivityLogsSuccess(response.data.data));
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
    }
  };
}

// DELETE ACTIVITY LOGS
export function deleteActivityLogs(ids) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await customAxios.delete('/userlog/bulk-delete', {
        data: { ids },
      });
      if (response.status === 200) {
        return true;
      }
      dispatch(slice.actions.hasError(response.data.message));
      return false;
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
      return false;
    }
  };
}

export function getUserFiles({ pageSize, page, sortOrder, sortBy, search }) {
  return async () => {
    await dispatch(slice.actions.startLoading());
    try {
      const queryParams = new URLSearchParams({
        pageSize,
        page,
        sortOrder,
        sortBy,
      });

      if (search?.length > 0) {
        queryParams.append('search', search);
      }
      const response = await customAxios.get(`/userfile/all?${queryParams.toString()}`);
      dispatch(operatorActions.getUserFilesSuccess(response.data.data));
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
    }
  };
}
// GET ROLES List
export function getRoles() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await customAxios.get('/roles');
      const { rolesList } = response?.data;
      dispatch(slice.actions.getRoleListSuccess(rolesList));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
}

// GET USER
export function getUser(payload) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await customAxios.get('/users', { ...payload });
    } catch (err) {
      dispatch(slice.actions.hasError(err));
    }
  };
}

// POST OPERATOR

export function postOperator(payload, isEdit, userId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let response;
      if (isEdit) {
        response = await customAxios.put(`user/edit/${userId}`, { ...payload });
      } else {
        response = await customAxios.post('/user/create', { ...payload });
      }
      const { status, message } = response?.data;
      if (status === 201) {
        dispatch(slice.actions.postOperatorSuccess());
      } else {
        dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// GET OPERATOR PROFILE

export function getOperatorProfile(userId, loading = true) {
  return async () => {
    if (!loading) {
      await dispatch(slice.actions.startLoading());
    }
    try {
      const response = await customAxios.get(`user/profile/${userId}`);
      await dispatch(slice.actions.getOperatorProfileSuccess({ ...response.data, loading }));
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getAdminProfile(userId) {
  return async () => {
    await dispatch(slice.actions.startLoading());
    try {
      const response = await customAxios.get(`user/profile/${userId}`);
      await dispatch(slice.actions.getAdminProfileSuccess(response.data));
      return response.data;
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}
export function getBrokerProfile(userId) {
  return async () => {
    await dispatch(slice.actions.startLoading());
    try {
      const response = await customAxios.get(`user/profile/${userId}`);
      await dispatch(slice.actions.getBrokerProfileSuccess(response.data));
      return response.data;
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}
export function getClientProfile(userId) {
  return async () => {
    await dispatch(slice.actions.startLoading());
    try {
      const response = await customAxios.get(`user/profile/${userId}`);
      await dispatch(slice.actions.getClientProfileSuccess(response.data));
      return response.data;
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// GET OPERATOR PROJECT LIST
export function getProjectList(pageSize, pageNumber, statusData, order, searchWord, cancelToken) {
  return async () => {
    let response;
    try {
      if (searchWord?.length > 0) {
        response = await customAxios.get(
          `/admin/operator/project/?status=${statusData}&order=${order}&pageSize=${pageSize}&page=${pageNumber}&search=${searchWord}`,
          { cancelToken: cancelToken.token }
        );
      } else {
        await dispatch(slice.actions.startLoading());
        response = await customAxios.get(
          `/admin/operator/project/?status=${statusData}&order=${order}&pageSize=${pageSize}&page=${pageNumber}`
        );
      }
      const { data, status, message } = response?.data;
      if (status === 200) {
        await dispatch(slice.actions.getOperatorProjectListSuccess(data));
      } else {
        await dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
      // if (axios.isCancel(error) || error) {
      //   // console.log(error);
      // }
    }
  };
}
// export function getScriptWiseTrade({
//   userId,
//   pageSize,
//   pageNumber,
//   statusData,
//   order,
//   sortBy,
//   searchWord,
//   type,
//   cancelToken,
//   segements=[],
// }) {
//   return async () => {
//     let response;
//     try {
//       await dispatch(slice.actions.startLoading());
//       if (searchWord?.length > 0) {
//         response = await customAxios.get(
//           `/trade/grouped-by-script/${userId}?status=${statusData}&sortOrder=${order}&sortBy=${sortBy}&pageSize=${pageSize}&page=${pageNumber}&search=${searchWord}&type=${type}`
//         );
//       } else {
//         // await dispatch(slice.actions.startLoading());
//         response = await customAxios.get(
//           `/trade/grouped-by-script/${userId}?status=${statusData}&sortOrder=${order}&sortBy=${sortBy}&pageSize=${pageSize}&page=${pageNumber}&type=${type}`
//         );
//       }
//       const { data, status, message } = response?.data;

//       if (status === 200) {
//         await dispatch(slice.actions.getScriptWiseTradeSuccess(data));
//       } else {
//         await dispatch(slice.actions.hasError(message));
//       }
//     } catch (error) {
//       if (cancelToken) {
//         cancelToken.cancel();
//       }
//     }
//   };
// }

export function getScriptWiseTrade({
  userId,
  pageSize,
  pageNumber,
  statusData,
  order,
  sortBy,
  searchWord,
  type,
  cancelToken,
  segements = [], // Use 'segments' for consistency
}) {
  return async () => {
    let response;
    try {
      await dispatch(slice.actions.startLoading());

      // Create a new URLSearchParams object
      const queryParams = new URLSearchParams();

      // Append basic query parameters
      queryParams.append('status', statusData);
      queryParams.append('sortOrder', order);
      queryParams.append('sortBy', sortBy);
      queryParams.append('pageSize', pageSize);
      queryParams.append('page', pageNumber);
      queryParams.append('type', type);

      // Append searchWord if available
      if (searchWord?.length > 0) {
        queryParams.append('search', searchWord);
      }

      if (segements.length > 0) {
        // Append segments as separate query parameters
        segements.forEach((segment) => {
          queryParams.append('segements', segment); // Use 'segments[]' to indicate an array
        });
      }
      // Construct the full URL
      const url = `/trade/grouped-by-script/${userId}?${queryParams.toString()}`;

      response = await customAxios.get(url);

      const { data, status, message } = response?.data;

      if (status === 200) {
        await dispatch(slice.actions.getScriptWiseTradeSuccess(data));
      } else {
        await dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      if (cancelToken) {
        cancelToken.cancel();
      }
      await dispatch(slice.actions.hasError(error.message));
    }
  };
}

export function getUserBalanceSummary({ userId, pageSize = 10, pageNumber = 1, sortBy, sortOrder, searchWord }) {
  return async () => {
    let response;
    try {
      dispatch(slice.actions.startLoading());
      const queryParams = new URLSearchParams({
        pageSize,
        page: pageNumber,
        sortOrder,
        sortBy,
      });

      if (searchWord?.length > 0) {
        queryParams.append('search', searchWord);
      }

      const url = `/user/clients/balance-summary${userId ? `/${userId}` : ''}?${queryParams.toString()}`;

      response = await customAxios.get(url);

      dispatch(slice.actions.getUserBalanceSummarySuccess(response?.data?.data));
      dispatch(slice.actions.getUserMoneyDetailSuccess(response?.data?.summary));
    } catch (error) {
      dispatch(slice.actions.hasError(error?.response?.data));
      // if (customAxios.isCancel(error)) {
      //   throw error?.response?.data;
      // }
    }
  };
}

export function getAllUsersTradesForCurrentScript({
  scriptId,
  pageSize,
  pageNumber,
  statusData,
  order,
  sortBy,
  searchWord,
  type,
  cancelToken,
}) {
  return async () => {
    let response;
    try {
      await dispatch(slice.actions.startLoading());
      if (searchWord?.length > 0) {
        response = await customAxios.get(
          `/trade/all/${scriptId}?status=${statusData}&sortOrder=${order}&sortBy=${sortBy}&pageSize=${pageSize}&page=${pageNumber}&search=${searchWord}&type=${type}`
        );
      } else {
        response = await customAxios.get(
          `/trade/all/${scriptId}?status=${statusData}&sortOrder=${order}&sortBy=${sortBy}&pageSize=${pageSize}&page=${pageNumber}&type=${type}`
        );
      }
      const { data, status, message } = response?.data;

      if (status === 200) {
        await dispatch(slice.actions.getcurrentScriptClientTradeSuccess(data));
      } else {
        await dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
      if (cancelToken) {
        cancelToken.cancel();
      }
    }
  };
}

// PUT PROJECT

export function putProjectList(projectID, statusTab, notes, filterStatus) {
  return async () => {
    await dispatch(slice.actions.startLoading());
    try {
      const response = await customAxios.put(`/admin/operator/project/${projectID}?${statusTab}=true`);
      const { status, newStatus, message } = response?.data;
      if (status === 200) {
        await dispatch(
          slice.actions.updateOperatorProjectListSuccess({ projectID, statusTab, filterStatus, newStatus })
        );
      } else {
        await dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
    }
  };
}

// GET OPERATOR PROJECT PROFILE

export function getOperatorProjectProfile(projectID) {
  return async () => {
    await dispatch(slice.actions.startLoading());
    try {
      const response = await customAxios.get(`admin/operator/project/${projectID}`);
      const { status, data, message } = response?.data;
      if (status === 200) {
        await dispatch(slice.actions.getOperatorProjectProfileSuccess(data));
      } else {
        await dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
    }
  };
}

// GET PROJECT BY OPERATOR ID

export function getProjectByID(pageSize, pageNumber, statusData, order, id, searchWord, cancelToken) {
  return async () => {
    let response;
    try {
      if (searchWord?.length > 0) {
        response = await customAxios.get(
          `/admin/operator/project/list/${id}?status=${statusData}&order=${order}&pageSize=${pageSize}&page=${pageNumber}&search=${searchWord}`,
          { cancelToken: cancelToken.token }
        );
      } else {
        await dispatch(slice.actions.startLoading());
        response = await customAxios.get(
          `/admin/operator/project/list/${id}?status=${statusData}&order=${order}&pageSize=${pageSize}&page=${pageNumber}`
        );
      }
      const { data, status, message } = response?.data;
      if (status === 200) {
        await dispatch(slice.actions.getProjectByIDSuccess(data));
      } else {
        await dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      await dispatch(slice.actions.hasError(error));
      // if (axios.isCancel(error) || error) {
      //   // console.log(error);
      // }
    }
  };
}
