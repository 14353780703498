import {
  Dashboard,
  People,
  Assessment,
  Settings,
  AccountCircle,
  Summarize,
  LockReset,
  Bookmark,
  Assignment,
  Tune,
  Groups,
  SwitchAccount,
  ViewList,
  PlayArrow,
  HourglassBottom,
  AssignmentTurnedIn,
  Stop,
  Security,
  Troubleshoot,
  Difference,
  CloudDownload,
  NoEncryptionGmailerrorred,
} from '@mui/icons-material';
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  chat: getIcon('ic_chat'),
  user: getIcon('ic_user'),
  dashboard: <Dashboard />,
  insurance: getIcon('ic_insurance'),
  userList: <People />,
  tradeList: <Assessment />,
  activityLogs: <Assignment />,
  settings: <Settings />,
  reports: <Summarize />,
  weekelyReports: <CloudDownload />,
  profile: <AccountCircle />,
  watchlist: <Bookmark />,
  equityLotSettings: <Tune />,
  generalSettings: <Settings />,
  executedTrades: <AssignmentTurnedIn />,
  pendingTrades: <HourglassBottom />,
  changePassword: <LockReset />,
  lineTrades: <Security />,
  clientUsers: <Groups />,
  brokerUsers: <SwitchAccount />,
  positions: <ViewList />,
  activeTrades: <PlayArrow />,
  closedTrades: <Stop />,
  riskReport: <Troubleshoot />,
  plHistory: <Difference />,
  ban: <NoEncryptionGmailerrorred />,
};

const adminNavConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    // subheader: 'management',
    items: [
      { title: 'dashboard', path: PATH_DASHBOARD.insurerRole.brokerlist.root, icon: ICONS.dashboard },
      { title: 'watchlist', path: PATH_DASHBOARD.insurerRole.watchlist.root, icon: ICONS.watchlist },
      { title: 'activity logs', path: PATH_DASHBOARD.insurerRole.log.root, icon: ICONS.activityLogs },
      { title: 'Brokers', path: PATH_DASHBOARD.insurerRole.broker.root, icon: ICONS.brokerUsers },
      { title: 'Clients', path: PATH_DASHBOARD.insurerRole.client.root, icon: ICONS.clientUsers },

      {
        title: 'Positions',
        icon: ICONS.positions,
        path: PATH_DASHBOARD.insurerRole.positions.grouped.root,
        children: [
          { title: 'Active', path: PATH_DASHBOARD.insurerRole.positions.grouped.active, icon: ICONS.activeTrades },
          { title: 'Closed', path: PATH_DASHBOARD.insurerRole.positions.grouped.closed, icon: ICONS.closedTrades },
        ],
      },
      {
        title: 'trades',
        icon: ICONS.tradeList,
        children: [
          { title: 'Executed', path: PATH_DASHBOARD.insurerRole.trades.executed, icon: ICONS.executedTrades },
          { title: 'Pending', path: PATH_DASHBOARD.insurerRole.trades.pending, icon: ICONS.pendingTrades },
        ],
      },

      {
        title: 'settings',
        icon: ICONS.settings,
        children: [
          {
            title: 'Equity Lot Settings',
            path: PATH_DASHBOARD.insurerRole.setting.equity,
            icon: ICONS.equityLotSettings,
          },
          {
            title: 'Change Password',
            path: PATH_DASHBOARD.insurerRole.setting.changePassword,
            icon: ICONS.changePassword,
          },
          // { title: 'General settings', path: PATH_DASHBOARD.insurerRole.setting.general, icon: ICONS.generalSettings },
        ],
      },
      {
        title: 'Reports',
        icon: ICONS.reports,
        children: [
          {
            title: 'P&L Summary',
            path: PATH_DASHBOARD.insurerRole.report.plHistory,
            icon: ICONS.plHistory,
          },
          {
            title: 'Ban Scripts',
            path: PATH_DASHBOARD.insurerRole.report.banScripts,
            icon: ICONS.ban,
          },
          {
            title: 'Risk Report',
            path: PATH_DASHBOARD.insurerRole.report.riskyUser,
            icon: ICONS.riskReport,
          },
          {
            title: 'Weekly Settlement',
            path: PATH_DASHBOARD.insurerRole.report.weekly,
            icon: ICONS.weekelyReports,
          },
          { title: 'Alert Trades', path: PATH_DASHBOARD.insurerRole.lineTrades.root, icon: ICONS.lineTrades },
          // { title: 'General settings', path: PATH_DASHBOARD.insurerRole.setting.general, icon: ICONS.generalSettings },
        ],
      },
    ],
  },
  // {// { title: 'profile', path: PATH_DASHBOARD.insurerRole.profile.root, icon: ICONS.profile },
  //   subheader: 'accounts',
  //   items: [
  //     { title: 'watchlist', path: PATH_DASHBOARD.insurerRole.watchlist.root, icon: ICONS.watchlist },
  //   ],
  // },
];

export default adminNavConfig;
