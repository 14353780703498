import React, { useEffect, useState } from 'react';
import {
  Card,
  Container,
  Button,
  Typography,
  Stack,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Switch,
  Tooltip,
} from '@mui/material';
import { Edit, Delete, Visibility } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { dispatch } from '../../../redux/store';
import { broadCastMessageOperations } from '../../../redux/slices/operator';

export default function MessageBroadcast() {
  useEffect(() => {
    dispatch(broadCastMessageOperations());
  }, []);

  const resultData = useSelector((state) => state?.operator?.broadCastMessage);
  const [messages, setMessages] = useState(resultData?.data || []);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [currentMessage, setCurrentMessage] = useState({ message: '' });
  const [isEdit, setIsEdit] = useState(false);
  const [isTextValid, setIsTextValid] = useState(true);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [viewMessage, setViewMessage] = useState('');

  const handleDialogOpen = (message = { message: '' }, edit = false) => {
    setCurrentMessage(message);
    setIsEdit(edit);
    setDialogOpen(true);
    setIsTextValid(true);
  };

  useEffect(() => {
    setMessages(resultData?.data || []);
  }, [resultData]);

  const handleDialogClose = () => {
    setDialogOpen(false);
    setCurrentMessage({ message: '' });
    setIsEdit(false);
  };

  const handleSaveMessage = async () => {
    if (!currentMessage.message.trim()) {
      setIsTextValid(false);
      return;
    }

    try {
      const payload = {
        message: currentMessage.message,
        category: 'info',
        flash: false,
      };

      if (isEdit) {
        await dispatch(broadCastMessageOperations(payload, true, currentMessage._id));
      } else {
        await dispatch(broadCastMessageOperations(payload, false));
      }
      dispatch(broadCastMessageOperations());
      handleDialogClose();
    } catch (error) {
      console.error('Error saving message:', error);
    }
  };

  const openDeleteDialog = (id) => {
    setSelectedMessageId(id);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setSelectedMessageId(null);
  };

  const confirmDeleteMessage = async () => {
    try {
      await dispatch(broadCastMessageOperations(null, null, selectedMessageId));
      dispatch(broadCastMessageOperations());
      closeDeleteDialog();
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  const toggleFlash = async (id) => {
    try {
      const updatedMessages = messages.map((msg) => {
        if (msg._id === id) {
          const toggledFlash = !msg.flash;
          const payload = {
            message: msg.message,
            category: 'info',
            flash: toggledFlash,
          };
          dispatch(broadCastMessageOperations(payload, true, id));
          return { ...msg, flash: toggledFlash };
        }
        return msg;
      });

      setMessages(updatedMessages);
    } catch (error) {
      console.error('Error toggling flash:', error);
    }
  };

  return (
    <Page title="Message Broadcast">
      <Container maxWidth="lg" sx={{ px: { xs: 2, md: 4 }, py: { xs: 2, md: 3 } }}>
        <HeaderBreadcrumbs
          heading="MESSAGE BROADCAST MANAGEMENT"
          links={[{ name: 'Dashboard', href: '/dashboard' }, { name: 'MESSAGE BROADCAST MANAGEMENT' }]}
        />

        <Card sx={{ p: 3, boxShadow: 2, borderRadius: 3, bgcolor: 'background.paper' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
            <Typography variant="h6">{}</Typography>
            <Button variant="contained" onClick={() => handleDialogOpen()}>
              CREATE MESSAGE
            </Button>
          </Stack>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>MESSAGE</TableCell>
                  <TableCell>FLASH</TableCell>
                  <TableCell>CREATED DATE</TableCell>
                  <TableCell>ACTIONS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {messages.map((message) => (
                  <TableRow key={message?._id}>
                    <TableCell>{message?.message.slice(0, 100)}...</TableCell>
                    <TableCell>
                      <Switch
                        checked={message?.flash}
                        onChange={() => toggleFlash(message?._id)}
                        sx={{
                          '& .MuiSwitch-thumb': {
                            backgroundColor: message?.flash ? 'green' : 'primary',
                          },
                          '& .MuiSwitch-track': {
                            backgroundColor: message?.flash ? 'green' : 'primary',
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>{new Date(message?.createdAt).toLocaleString()}</TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Tooltip title="View">
                          <IconButton onClick={() => setViewMessage(message?.message)}>
                            <Visibility />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit">
                          <IconButton onClick={() => handleDialogOpen(message, true)}>
                            <Edit />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton color="error" onClick={() => openDeleteDialog(message._id)}>
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>

        {/* Dialog for Create/Edit Message */}
        <Dialog open={isDialogOpen} onClose={handleDialogClose} maxWidth="sm" fullWidth>
          <DialogTitle>{isEdit ? 'EDIT MESSAGE' : 'CREATE MESSAGE'}</DialogTitle>
          <DialogContent>
            <Stack spacing={2} marginTop={2}>
              <TextField
                label="MESSAGE TEXT"
                multiline
                rows={4}
                value={currentMessage.message}
                onChange={(e) => {
                  setCurrentMessage({ ...currentMessage, message: e.target.value });
                  setIsTextValid(true);
                }}
                fullWidth
                error={!isTextValid}
                helperText={!isTextValid && 'Message text is required'}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleSaveMessage} variant="contained">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialog for Delete Confirmation */}
        <Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog} maxWidth="xs" fullWidth>
          <DialogTitle>CONFIRM DELETE</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this message?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDeleteDialog} color="secondary">
              Cancel
            </Button>
            <Button onClick={confirmDeleteMessage} color="error" variant="contained">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* View Message Dialog */}
        <Dialog
          open={!!viewMessage}
          onClose={() => setViewMessage('')}
          maxWidth="sm"
          fullWidth
          sx={{
            '& .MuiPaper-root': {
              border: '2px solid #1976d2', // Add border to the dialog
              borderRadius: '8px', // Optional: rounded corners for a modern look
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow
            },
          }}
        >
          <DialogTitle
            sx={{
              textAlign: 'center',
              backgroundColor: '#1976d2',
              color: '#fff',
              padding: '8px 16px',
              fontWeight: 'bold',
              fontSize: '1.25rem',
            }}
          >
            FULL MESSAGE
          </DialogTitle>

          <DialogContent
            sx={{
              marginTop: '4px',
              padding: '16px',
              border: '1px solid #e0e0e0', // Internal border for message box
              borderRadius: '4px',
              backgroundColor: '#f9f9f9', // Light background for better contrast
              whiteSpace: 'pre-wrap', // Ensures spacing and newlines are preserved
              fontFamily: 'Arial, sans-serif',
              fontSize: '1rem',
              lineHeight: '1.5',
            }}
          >
            <Typography
              dangerouslySetInnerHTML={{
                __html: viewMessage.replace(/\n/g, '<br />'),
              }}
            />
          </DialogContent>

          <DialogActions
            sx={{
              justifyContent: 'center',
              padding: '16px',
            }}
          >
            <Button
              onClick={() => setViewMessage('')}
              variant="contained"
              sx={{
                textTransform: 'none',
                fontSize: '1rem',
                padding: '8px 16px',
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
}
