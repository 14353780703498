import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from '@mui/material';

import { FormProvider, RHFAutoComplete } from '../../../components/hook-form';
import { setBanScript } from '../../../redux/slices/operator';
import { dispatch } from '../../../redux/store';

export default function BanScriptsForm({ instrumentList, allBanScripts }) {
  const { enqueueSnackbar } = useSnackbar();
  const schema = yup.object().shape({
    banScripts: yup.array().of(yup.object().required('At least one script is required')),
  });
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      banScripts: allBanScripts,
    },
  });

  useEffect(() => {
    methods.reset({ banScripts: allBanScripts });
  }, [allBanScripts, methods]);

  const { handleSubmit } = methods;

  const onSubmit = async (data) => {
    try {
      const response = await dispatch(setBanScript(data.banScripts));
      enqueueSnackbar(response.message, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('An error occurred while updating ban scripts', { variant: 'error' });
    }
  };
  return (
    <Card
      sx={{
        p: { xs: 1, md: 2 },
        mb: 2,
        boxShadow: 2,
        borderRadius: 3,
        bgcolor: 'background.paper',
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <RHFAutoComplete
          name="banScripts"
          options={instrumentList}
          label="Banned Scripts"
          placeholder="Select Script to Ban"
          value={methods.getValues('banScripts')}
        />
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Ban Script
        </Button>
      </FormProvider>
    </Card>
  );
}

BanScriptsForm.propTypes = {
  instrumentList: PropTypes.array.isRequired,
  allBanScripts: PropTypes.array.isRequired,
};
