/* eslint-disable react-hooks/exhaustive-deps */
// @mui
import { Card, MenuItem, Container, Typography, TextField, Box } from '@mui/material';

// React and Redux
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';

// Routes and Utilities
import { format, getISOWeek, getYear, startOfWeek, subWeeks } from 'date-fns';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import useAuth from '../../../hooks/useAuth';

// Components
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { dispatch } from '../../../redux/store';
import { getWeeklyPLofUsers } from '../../../redux/slices/operator';
import PLSummaryData from './PLSummaryDataTable';

// ----------------------------------------------------------------------

// function getDropDownRoles(loginUserRole) {
//   const roleMap = {
//     superAdmin: ['admin', 'broker', 'client'],
//     admin: ['broker', 'client'],
//     broker: ['client'],
//   };
//   return roleMap[loginUserRole] || [];
// }

export default function PlHistorySummary() {
  const { themeStretch } = useSettings();
  const { user } = useAuth();
  const location = useLocation();
  const selectedRole = 'client';

  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState({ isoWeekCode: null, year: null });

  const getWeekRange = (date) => {
    const weekStart = startOfWeek(date, { weekStartsOn: 1 });
    const weekEnd = new Date(weekStart);
    weekEnd.setDate(weekStart.getDate() + 5); // Saturday
    return `${format(weekStart, 'dd-MMM')} to ${format(weekEnd, 'dd-MMM')}`;
  };

  const generateDropdownOptions = () => {
    const today = new Date();
    const isSaturdayOrLater = today.getDay() === 6; // 6 = Saturday

    // Shift the base week based on the day of the week
    const baseDate = isSaturdayOrLater ? today : subWeeks(today, 1);

    // Generate week ranges
    const currentWeekRange = getWeekRange(baseDate);
    const previousWeekRange = getWeekRange(subWeeks(baseDate, 1));
    const twoWeeksAgoRange = getWeekRange(subWeeks(baseDate, 2));

    const options = [
      {
        label: `Current/Last Week: ${currentWeekRange}`,
        value: { isoWeekCode: getISOWeek(baseDate), year: getYear(baseDate) },
      },
      {
        label: `Previous Week: ${previousWeekRange}`,
        value: { isoWeekCode: getISOWeek(subWeeks(baseDate, 1)), year: getYear(subWeeks(baseDate, 1)) },
      },
      {
        label: `Two Weeks Ago: ${twoWeeksAgoRange}`,
        value: { isoWeekCode: getISOWeek(subWeeks(baseDate, 2)), year: getYear(subWeeks(baseDate, 2)) },
      },
    ];

    setDropdownOptions(options);
    setSelectedWeek(options[0].value); // Default selection
  };

  useEffect(() => {
    if (selectedWeek.isoWeekCode) {
      const { isoWeekCode, year } = selectedWeek;
      dispatch(getWeeklyPLofUsers({ selectedRole, isoWeekCode, year }));
    }
  }, [selectedWeek]);

  useEffect(() => {
    generateDropdownOptions();
  }, []);

  const resultData = useSelector((state) => state?.operator);
  const tableData = resultData?.weeklyPLofUsers?.data || [];

  return (
    <Page title="P/L-Summary">
      <Container maxWidth={themeStretch ? false : 'lg'} sx={{ px: { xs: 2, md: 4 }, py: { xs: 2, md: 3 } }}>
        <HeaderBreadcrumbs
          heading="P/L SUMMARY REPORT"
          links={[
            { name: 'Dashboard', href: `/${location.pathname.slice(1).split('/')[0]}` },
            { name: 'P&L Summary', href: PATH_DASHBOARD.operatorRole.clients.new },
          ]}
        />

        <Card
          sx={{
            p: { xs: 1, md: 2 },
            boxShadow: 2,
            borderRadius: 3,
            bgcolor: 'background.paper',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: { md: 'center' },
              gap: 3,
              mb: 3,
            }}
          >
            <TextField
              select
              label="Settlement Week"
              value={JSON.stringify(selectedWeek)}
              onChange={(e) => {
                const selected = JSON.parse(e.target.value);
                setSelectedWeek(selected);
              }}
              variant="outlined"
              size="small"
              sx={{
                flex: 1,
                minWidth: { xs: '100%', md: '50%' },
                maxWidth: { xs: '100%', md: '50%' },
              }}
            >
              {dropdownOptions.map((option, index) => (
                <MenuItem key={index} value={JSON.stringify(option.value)}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          {selectedWeek ? (
            <PLSummaryData
              tableData={tableData}
              ReportingUserRole={selectedRole}
              loginRole={user?.role?.rolename}
              sx={{
                mt: 4,
                overflowX: 'auto',
                maxWidth: '100%',
              }}
            />
          ) : (
            <Typography
              variant="subtitle1"
              sx={{
                mt: 2,
                textAlign: 'center',
                color: 'text.secondary',
                fontSize: { xs: '0.9rem', md: '1rem' },
              }}
            >
              Please select a sattlement week to view the P/L summary report.
            </Typography>
          )}
        </Card>
      </Container>
    </Page>
  );
}
