/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Box,
  TextField,
} from '@mui/material';

export default function PLSummaryData({ tableData, ReportingUserRole, loginRole }) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('username');
  const [searchTerm, setSearchTerm] = useState('');
  const [adminSearch, setAdminSearchTerm] = useState('');
  const [brokerSearch, setBrokerSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(tableData);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleAdminSearchChange = (event) => {
    setAdminSearchTerm(event.target.value);
  };
  const handleBrokerSearchChange = (event) => {
    setBrokerSearchTerm(event.target.value);
  };

  // ------------ Filter Table Data By Search Value ------------------------
  useEffect(() => {
    const filtered = tableData.filter((record) => {
      const usernameMatch = record.creator?.username?.toLowerCase().includes(searchTerm.toLowerCase());
      const usernameFirstMatch = record?.creator?.firstname?.toLowerCase().includes(searchTerm.toLowerCase());
      const usernameLastMatch = record?.creator?.lastname?.toLowerCase().includes(searchTerm.toLowerCase());

      const brokerMatch =
        brokerSearch === '' ||
        (record.broker?.username && record.broker.username.toLowerCase().includes(brokerSearch.toLowerCase()));
      const brokerFirstMatch =
        brokerSearch === '' ||
        (record.broker?.firstname && record.broker.firstname.toLowerCase().includes(brokerSearch.toLowerCase()));
      const brokerLastMatch =
        brokerSearch === '' ||
        (record.broker?.lastname && record.broker.lastname.toLowerCase().includes(brokerSearch.toLowerCase()));

      const adminMatch =
        adminSearch === '' ||
        (record.admin?.username && record.admin.username.toLowerCase().includes(adminSearch.toLowerCase()));
      const adminFirstMatch =
        adminSearch === '' ||
        (record.admin?.firstname && record.admin.firstname.toLowerCase().includes(adminSearch.toLowerCase()));
      const adminLastMatch =
        adminSearch === '' ||
        (record.admin?.lastname && record.admin.lastname.toLowerCase().includes(adminSearch.toLowerCase()));

      return (
        (usernameMatch || usernameFirstMatch || usernameLastMatch) &&
        (brokerMatch || brokerFirstMatch || brokerLastMatch) &&
        (adminMatch || adminFirstMatch || adminLastMatch)
      );
    });
    setFilteredData(filtered);
  }, [searchTerm, brokerSearch, adminSearch, tableData]);

  // ---------------- Get Selected Role wise P/L details ---------------------
  const getPLDetails = (record) => {
    let bookedPL;
    let brokerage;
    let grossPL;

    if (ReportingUserRole === 'admin') {
      bookedPL = record?.totalBookedProfitLoss?.admin || 0;
      brokerage = record?.totalBrokerage?.admin || 0;
      grossPL = record?.totalGrossProfitLoss?.admin || 0;
    } else if (ReportingUserRole === 'broker') {
      bookedPL = record?.totalBookedProfitLoss?.broker || 0;
      brokerage = record?.totalBrokerage?.broker || 0;
      grossPL = record?.totalGrossProfitLoss?.broker || 0;
    } else {
      bookedPL = record?.totalBookedProfitLoss?.client || 0;
      brokerage = record?.totalBrokerage?.client || 0;
      grossPL = record?.totalGrossProfitLoss?.client || 0;
    }
    return { bookedPL, brokerage, grossPL };
  };

  // ------------ Apply and Get Sorting Filter Data----------------------
  const sortedData = [...filteredData].sort((a, b) => {
    if (orderBy === 'username') {
      return order === 'asc'
        ? a.creator?.username?.localeCompare(b.creator?.username || '')
        : b.creator?.username?.localeCompare(a.creator?.username || '');
    }
    if (orderBy === 'admin') {
      return order === 'asc'
        ? a.broker?.username?.localeCompare(b.broker?.username || '')
        : b.broker?.username?.localeCompare(a.broker?.username || '');
    }
    if (orderBy === 'broker') {
      return order === 'asc'
        ? a.admin?.username?.localeCompare(b.admin?.username || '')
        : b.admin?.username?.localeCompare(a.admin?.username || '');
    }
    if (orderBy === 'grossPL') {
      const aGrossPL = getPLDetails(a).grossPL;
      const bGrossPL = getPLDetails(b).grossPL;
      return order === 'asc' ? aGrossPL - bGrossPL : bGrossPL - aGrossPL;
    }
    if (orderBy === 'brokerage') {
      const aBrokerage = getPLDetails(a).brokerage;
      const bBrokerage = getPLDetails(b).brokerage;
      return order === 'asc' ? aBrokerage - bBrokerage : bBrokerage - aBrokerage;
    }
    if (orderBy === 'bookedPL') {
      const aBookedPL = getPLDetails(a).bookedPL;
      const bBookedPL = getPLDetails(b).bookedPL;
      return order === 'asc' ? aBookedPL - bBookedPL : bBookedPL - aBookedPL;
    }
    return 0;
  });

  // ---------------- Helper Method Of Update Prices To Indian Currency ----------
  const formatCurrency = (value) =>
    new Intl.NumberFormat('en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);

  return (
    <Box sx={{ mt: 3 }}>
      {/* <Typography
        variant="h6"
        sx={{
          mb: 2,
          textTransform: 'uppercase',
          fontWeight: 'bold',
          color: 'text.primary',
          letterSpacing: 1,
        }}
      >
        {`${ReportingUserRole}s P/L DATA TABLE`}
      </Typography> */}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
          mb: 2,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <TextField
          label="Search Username"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          size="small"
          sx={{
            flex: 1,
            minWidth: { xs: '100%', sm: '300px' },
            '& .MuiOutlinedInput-root': {
              borderRadius: 2,
            },
          }}
        />
        {loginRole !== 'broker' && ReportingUserRole === 'client' && (
          <TextField
            label="Search Broker"
            variant="outlined"
            value={brokerSearch}
            onChange={handleBrokerSearchChange}
            size="small"
            sx={{
              flex: 1,
              minWidth: { xs: '100%', sm: '300px' },
              '& .MuiOutlinedInput-root': {
                borderRadius: 2,
              },
            }}
          />
        )}
        {loginRole === 'superAdmin' && ReportingUserRole === 'client' && (
          <TextField
            label="Search Admin"
            variant="outlined"
            value={adminSearch}
            onChange={handleAdminSearchChange}
            size="small"
            sx={{
              flex: 1,
              minWidth: { xs: '100%', sm: '300px' },
              '& .MuiOutlinedInput-root': {
                borderRadius: 2,
              },
            }}
          />
        )}
      </Box>

      {/* ---------------- Table Section ------------------- */}
      <TableContainer component={Paper} sx={{ borderRadius: 3, boxShadow: 4 }}>
        <Table>
          {/* --------------- Table Header Rowa ---------------------- */}
          <TableHead>
            <TableRow sx={{ bgcolor: 'primary.main' }}>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>
                <TableSortLabel
                  active={orderBy === 'username'}
                  direction={orderBy === 'username' ? order : 'asc'}
                  onClick={() => handleSort('username')}
                  sx={{ color: 'black', '&.Mui-active': { color: 'black' } }}
                >
                  USERNAME
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>
                <TableSortLabel
                  active={orderBy === 'grossPL'}
                  direction={orderBy === 'grossPL' ? order : 'asc'}
                  onClick={() => handleSort('grossPL')}
                  sx={{ color: 'black', '&.Mui-active': { color: 'black' } }}
                >
                  GROSS P/L
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>
                <TableSortLabel
                  active={orderBy === 'bookedPL'}
                  direction={orderBy === 'bookedPL' ? order : 'asc'}
                  onClick={() => handleSort('bookedPL')}
                  sx={{ color: 'black', '&.Mui-active': { color: 'black' } }}
                >
                  BOOKED P/L
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>
                <TableSortLabel
                  active={orderBy === 'brokerage'}
                  direction={orderBy === 'brokerage' ? order : 'asc'}
                  onClick={() => handleSort('brokerage')}
                  sx={{ color: 'black', '&.Mui-active': { color: 'black' } }}
                >
                  BROKERAGE
                </TableSortLabel>
              </TableCell>
              {loginRole !== 'broker' && ReportingUserRole === 'client' && (
                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>
                  <TableSortLabel
                    active={orderBy === 'broker'}
                    direction={orderBy === 'broker' ? order : 'asc'}
                    onClick={() => handleSort('broker')}
                    sx={{ color: 'black', '&.Mui-active': { color: 'black' } }}
                  >
                    BROKER
                  </TableSortLabel>
                </TableCell>
              )}
              {loginRole === 'superAdmin' && ReportingUserRole === 'client' && (
                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>
                  <TableSortLabel
                    active={orderBy === 'admin'}
                    direction={orderBy === 'admin' ? order : 'asc'}
                    onClick={() => handleSort('admin')}
                    sx={{ color: 'black', '&.Mui-active': { color: 'black' } }}
                  >
                    ADMIN
                  </TableSortLabel>
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          {/* -------------------------- Table Body Rows ----------------------- */}
          <TableBody>
            {sortedData.map((record) => {
              const { bookedPL, brokerage, grossPL } = getPLDetails(record);
              const userRefName =
                record?.creator?.firstname !== '' && record?.creator?.lastname !== ''
                  ? `(${record.creator.firstname} ${record.creator.lastname})`
                  : record?.creator?.lastname !== ''
                  ? `(${record.creator.lastname})`
                  : record?.creator?.firstname !== ''
                  ? `(${record.creator.firstname})`
                  : '';
              let brokerRefName;
              let adminRefName;
              if (loginRole !== 'broker' && ReportingUserRole === 'client') {
                brokerRefName =
                  record?.broker?.firstname !== '' && record?.broker?.lastname !== ''
                    ? `(${record?.broker?.firstname} ${record.broker.lastname})`
                    : record?.broker?.lastname !== ''
                    ? `(${record?.broker?.lastname})`
                    : record?.broker?.firstname !== ''
                    ? `(${record?.broker?.firstname})`
                    : '';
              }
              if (loginRole === 'superAdmin' && ReportingUserRole === 'client') {
                adminRefName =
                  record?.admin?.firstname !== '' && record?.admin?.lastname !== ''
                    ? `(${record?.admin?.firstname} ${record.admin.lastname})`
                    : record?.admin?.lastname !== ''
                    ? `(${record?.admin?.lastname})`
                    : record?.admin?.firstname !== ''
                    ? `(${record?.admin?.firstname})`
                    : '';
              }

              const getColor = (value) => {
                if (value > 0) return 'green';
                if (value < 0) return 'red';
                return 'blue';
              };

              return (
                <TableRow key={record._id}>
                  <TableCell sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
                    {`${record?.creator?.username} ${userRefName}` || 'N/A'}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: getColor(grossPL),
                      fontWeight: 'bold',
                    }}
                  >
                    {formatCurrency(grossPL)}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: getColor(bookedPL),
                      fontWeight: 'bold',
                    }}
                  >
                    {formatCurrency(bookedPL)}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: 'blue',
                      fontWeight: 'bold',
                    }}
                  >
                    {formatCurrency(brokerage)}
                  </TableCell>
                  {loginRole !== 'broker' && ReportingUserRole === 'client' && (
                    <TableCell sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
                      {`${record?.broker?.username} ${brokerRefName}` || 'N/A'}
                    </TableCell>
                  )}
                  {loginRole === 'superAdmin' && ReportingUserRole === 'client' && (
                    <TableCell sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
                      {`${record?.admin?.username} ${adminRefName}` || 'N/A'}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
            {filteredData.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} align="center" sx={{ textTransform: 'uppercase', color: 'text.secondary' }}>
                  no data found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
