/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useParams } from 'react-router';
// @mui
import {
  Box,
  Card,
  Table,
  Tooltip,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  FormControlLabel,
  Switch,
  Typography,
  TableFooter,
  TableRow,
  TableCell,
} from '@mui/material';

import useTabs from '../../../hooks/useTabs';
import useSettings from '../../../hooks/useSettings';
import useTable from '../../../hooks/useTable';
import { dispatch } from '../../../redux/store';
import { getUserBalanceSummary } from '../../../redux/slices/operator';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
// import Scrollbar from '../../../components/Scrollbar';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { TableHeadCustom, TableNoData, TableSelectedActions } from '../../../components/table';
import TableLoader from '../../../components/table/TableLoader';
import { UserTableToolbar } from '../../../sections/@dashboard/user/list';
import axios from '../../../utils/axios';
import useAuth from '../../../hooks/useAuth';
import UsersBalanceSummaryRow from './UsersBalanceSummaryRow';
import SummaryView from './SummaryView';
import useTrade from '../../../hooks/useTrade';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { fColorNumber, fNumber } from '../../../utils/formatNumber';

// import useLocales from '../../../hooks/useLocales';

const BROKER_TABLE_HEAD = [
  { id: '#', align: 'left' },
  { id: 'username', label: 'Username', align: 'left' },
  { id: 'balance', label: 'Credit', align: 'left' },
  { id: 'blockedMargin', label: 'BlockedMargin', align: 'left' },
  { id: 'grossPl', label: 'GrossP/L', align: 'left' },
  { id: 'activeProfitLoss', label: 'ActiveP/L', align: 'left' },
  { id: 'totalProfit', label: 'BookedP/L', align: 'left' },
  { id: 'totalBrokerage', label: 'TotalBrokerage', align: 'left' },
  { id: 'refundBrokerage', label: 'RefundBrokerage', align: 'left' },
  { id: 'trader.config.profitLossShare', label: 'ClientSettings', align: 'left' },
];

const NON_BROKER_TABLE_HEAD = [
  { id: 'username', label: 'Username', align: 'left', subLabel: 'FullName' },
  { id: 'yourGrossPl', label: ' Admin GrossP/L', align: 'left', subLabel: 'Clients GrossP/L' },
  { id: 'yourActivePl', label: 'Admin ActiveP/L', align: 'left', subLabel: 'Clients ActiveP/L' },
  { id: 'yourProfitLoss', label: ' Admin BookedP/L', align: 'left', subLabel: 'Clients BookedP/L' },
  { id: 'yourBrokerage', label: 'Admin Brokerage', align: 'left', subLabel: 'Clients Brokerage' },
  { id: 'refundBrokerage', label: 'Refund Brokerage', align: 'left', subLabel: '-' },
  { id: 'trader.config.profitLossShare', label: 'Broker Settings', align: 'left', subLabel: '-' },
];

// eslint-disable-next-line react/prop-types
const TwoValueDisplay = ({ primaryValue, secondaryValue, primaryColor, secondaryColor, onClick }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', cursor: 'pointer' }} onClick={onClick}>
    <Typography variant="h7" color={primaryColor || 'text.primary'}>
      {primaryValue}
    </Typography>
    <Typography variant="caption2" color={secondaryColor || 'textSecondary'}>
      {secondaryValue}
    </Typography>
  </Box>
);

const ThreeValueDisplay = ({
  primaryValue,
  secondaryValue,
  thirdValue,
  primaryColor,
  secondaryColor,
  thirdColor,
  onClick,
}) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', cursor: 'pointer' }} onClick={onClick}>
    <Typography variant="h7" color={primaryColor || 'text.primary'}>
      {primaryValue}
    </Typography>
    <Typography variant="caption2" color={secondaryColor || 'textSecondary'}>
      {secondaryValue}
    </Typography>
    <Typography variant="caption2" color={thirdColor || 'textSecondary'}>
      {thirdValue}
    </Typography>
  </Box>
);

export default function UsersBalanceSummaryTable() {
  const { dense, orderBy, rowsPerPage, selected, setSelected, onSelectRow, onSelectAllRows, onSort, onChangeDense } =
    useTable({ defaultRowsPerPage: 10, defaultOrderBy: 'username', defaultOrder: 'asc' });
  const { themeStretch } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  // const { translate } = useLocales();
  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState('all');
  const [order, setOrder] = useState('asc');
  const { userId: userIdFromParams } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const ClientsData = useSelector((state) => state.operator);
  const [currentPage] = useState(1);
  const [userRole, setUserRole] = useState(null);
  const [userName, setUserName] = useState('');
  const [tableHead, setTableHead] = useState(NON_BROKER_TABLE_HEAD);
  const { localSocket } = useTrade();

  const tableData = ClientsData?.userbalanceSummary?.docs || [];
  const balanceDetail = ClientsData?.userMoneyDetails;
  const { currentTab: filterStatus } = useTabs('all');
  const { user } = useAuth(); // Get the authenticated user
  const userId = userIdFromParams || user?.id;
  useEffect(() => {
    // Fetch user details to determine role
    async function fetchUserDetails() {
      let currUser;
      try {
        if (userId) {
          const response = await axios.get(`/user/profile/${userId}`);
          currUser = response.data.user;
          setUserRole(currUser.role.rolename);
          setUserName(currUser.username);
        } else {
          currUser = user;
          setUserName(currUser.username);
          setUserRole(currUser.role.rolename);
        }

        // Set table head based on the role
        if (currUser.role.rolename === 'broker') {
          setTableHead(BROKER_TABLE_HEAD);
        } else {
          setTableHead(NON_BROKER_TABLE_HEAD);
        }
      } catch (error) {
        enqueueSnackbar('Failed to fetch user details', { variant: 'error' });
      }
    }

    fetchUserDetails();

    // Fetch balance summary based on user role
    dispatch(
      getUserBalanceSummary({
        userId,
        pageSize: 10,
        pageNumber: 1,
        filterStatus,
        sortOrder: order,
        sortBy: orderBy,
        searchWord: filterName,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, filterStatus, enqueueSnackbar, user]);

  useEffect(() => {
    const sendActivePositionsMessage = (pageNumber, pageSize, sortBy, sortOrder, search, userId) => {
      const message = {
        event: 'dashboard_subscribe',
        data: {
          page: pageNumber,
          pageSize,
          sortBy,
          sortOrder,
          id: userId,
          search,
        },
      };
      // dispatch(
      //   getUserBalanceSummary({
      //     userId,
      //     pageSize: 10,
      //     pageNumber: 1,
      //     filterStatus,
      //     sortOrder: order,
      //     sortBy: orderBy,
      //     searchWord: filterName,
      //   })
      // );

      if (localSocket && localSocket.readyState === WebSocket.OPEN) {
        localSocket.send(JSON.stringify(message));
      }
    };
    if (localSocket) {
      localSocket.onopen = () => {
        sendActivePositionsMessage(currentPage, rowsPerPage, orderBy, order, filterName, userIdFromParams || user._id);
      };
      sendActivePositionsMessage(currentPage, rowsPerPage, orderBy, order, filterName, userIdFromParams || user._id);
    }
    return () => {
      if (localSocket && localSocket.readyState === WebSocket.OPEN) {
        localSocket.send(JSON.stringify({ event: 'dashboard_unsubscribe' }));
      }
    };
  }, [rowsPerPage, currentPage, orderBy, order, localSocket, userIdFromParams, user?._id, filterName]); // Runs on mount and when type or rowsPerPage chan

  const handleFilterName = (filterName) => {
    try {
      dispatch(
        getUserBalanceSummary({
          userId,
          pageSize: 10,
          pageNumber: 1,
          filterStatus,
          sortOrder: order,
          sortBy: orderBy,
          searchWord: filterName,
        })
      );
      setFilterName(filterName);
    } catch (error) {
      if (axios.isCancel(error) || error) {
        console.log(error);
      }
    }
  };

  const handleActiveBuySellCLick = () => {
    navigate(PATH_DASHBOARD.insurerRole.positions.userwiseGrouped.active(userIdFromParams || user?._id));
  };

  const handleFilterRole = (name) => {
    setFilterRole(name);
    try {
      dispatch(
        getUserBalanceSummary({ userId, pageSize: 10, pageNumber: 1, filterStatus, sortOrder: orderBy, sortBy: order })
      );
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const handleDeleteRows = () => {
    setSelected([]);
  };

  const handleViewRow = async (id) => {
    if (!userId && user.role.rolename === 'admin') {
      navigate(`${location.pathname}/${id}`);
    } else if (!userId && user.role.rolename === 'superAdmin') {
      navigate(`${location.pathname}/${id}`);
    } else {
      if (userId && user.role.rolename === 'superAdmin' && !location.pathname.includes('broker')) {
        navigate(`${location.pathname}/broker/${id}`);
      } else {
        navigate(`${location.pathname}/client/${id}`);
      }
      if (user.role.rolename === 'admin') navigate(PATH_DASHBOARD.insurerRole.broker.client.view(id));
      if (user.role.rolename === 'broker') navigate(PATH_DASHBOARD.operatorRole.clients.view(id));
    }
  };

  const handleUserUpdate = () => {
    dispatch(
      getUserBalanceSummary({
        userId,
        pageSize: 10,
        pageNumber: 1,
        filterStatus,
        sortOrder: order,
        sortBy: orderBy,
        searchWord: filterName,
      })
    );
  };
  const dataFiltered = applySortFilter({
    tableData,
  });

  const totals = dataFiltered.reduce(
    (acc, curr) => {
      if (curr.trader.isDemoAccount) {
        return acc;
      }
      acc.activeProfitLoss += curr.activeProfitLoss || 0;
      acc.yourActivePl += curr.yourActivePl || 0;
      acc.totalBrokerage += curr.totalBrokerage || 0;
      acc.yourBrokerage += curr.yourBrokerage || 0;
      acc.totalProfit += curr.totalProfit || 0;
      acc.yourProfitLoss += curr.yourProfitLoss || 0;
      acc.grossPl += curr.grossPl || 0;
      acc.yourGrossPl += curr.yourGrossPl || 0;
      acc.refundBrokerage += curr.refundBrokerage || 0;
      acc.superAdminGrossPl += curr.superAdminGrossPl || 0;
      acc.superAdminActivePl += curr.superAdminActivePl || 0;
      acc.superAdminProfit += curr.superAdminProfit || 0;
      acc.superAdminBrokerage += curr.superAdminBrokerage || 0;
      return acc;
    },
    {
      activeProfitLoss: 0,
      yourActivePl: 0,
      totalBrokerage: 0,
      yourBrokerage: 0,
      totalProfit: 0,
      yourProfitLoss: 0,
      grossPl: 0,
      yourGrossPl: 0,
      refundBrokerage: 0,
      superAdminGrossPl: 0,
      superAdminActivePl: 0,
      superAdminProfit: 0,
      superAdminBrokerage: 0,
    }
  );

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

  return (
    <Page title="Trades">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={''}
          links={[
            { name: 'Dashboard', href: `/${location.pathname.slice(1).split('/')[0]}` }, // Base link to Dashboard
            // eslint-disable-next-line no-nested-ternary
            ...(user?.role?.rolename !== 'superAdmin'
              ? userId
                ? [{ name: user.username, href: `/${location.pathname.split('/').slice(1, -1).join('/')}` }]
                : []
              : userId
              ? [{ name: user.username, href: `/${location.pathname.split('/').slice(1, 3).join('/')}` }]
              : []),
            { name: userName || 'Balance' }, // Final link which is userName or default to 'Balance'
          ]}
          // links={[{ name: '' }]}
        />
        <Card sx={{ width: '100%' }}>
          {/* <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={(e, value) => {
              setCurrentTab(value);
            }}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
          </Tabs>

          <Divider /> */}
          <UserTableToolbar
            filterName={filterName}
            filterRole={filterRole}
            header={'DASHBOARD'}
            onFilterName={(name) => handleFilterName(name)}
            onFilterRole={(name) => handleFilterRole(name)}
            optionsRole={['all']}
            searchPlaceholder="Search Users..."
          />

          {/* <Scrollbar> */}
          <TableContainer sx={{ width: '100%', position: 'relative', maxHeight: 400 }}>
            {selected.length > 0 && (
              <TableSelectedActions
                dense={dense}
                numSelected={selected.length}
                rowCount={tableData.length}
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id)
                  )
                }
                onSort={(id) => {
                  onSort(id);
                  setOrder(order === 'asc' ? 'desc' : 'asc');
                }}
                actions={
                  <Tooltip title="Delete">
                    <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                      <Iconify icon={'eva:trash-2-outline'} />
                    </IconButton>
                  </Tooltip>
                }
              />
            )}

            <Table size={dense ? 'small' : 'medium'}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={tableHead}
                rowCount={tableData.length}
                numSelected={selected.length}
                sticky
                sx={{
                  position: 'sticky',
                  top: 0,
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'light' ? theme.palette.background.paper : theme.palette.background.default,
                  zIndex: 1,
                }}
                onSort={(id) => {
                  onSort(id);
                  dispatch(
                    getUserBalanceSummary({
                      userId,
                      pageSize: rowsPerPage,
                      pageNumber: 1,
                      searchWord: filterName,
                      filterName,
                      sortOrder: order === 'asc' ? 'desc' : 'asc',
                      sortBy: id,
                    })
                  );
                  setOrder(order === 'asc' ? 'desc' : 'asc');
                }}
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id)
                  )
                }
              />

              <TableBody>
                {ClientsData?.isLoading && <TableLoader />}
                {!ClientsData?.isLoading &&
                  dataFiltered?.length > 0 &&
                  dataFiltered.map((row) => (
                    <UsersBalanceSummaryRow
                      key={row?.trader?._id}
                      row={row}
                      filterStatus={filterStatus}
                      filterRole={filterRole}
                      loggedInUser={user}
                      role={userRole === 'broker' ? 'broker' : 'non-broker'}
                      selected={selected.includes(row?._id)}
                      onSelectRow={() => onSelectRow(row?._id)}
                      onViewRow={() => handleViewRow(row?.trader?._id)}
                      onUserUpdate={() => handleUserUpdate()}
                    />
                  ))}

                {/* <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} /> */}

                {dataFiltered?.length === 0 && !ClientsData?.isLoading && <TableNoData isNotFound={isNotFound} />}
              </TableBody>
              {/* </Table> */}
              <TableFooter
                sx={{
                  position: 'sticky',
                  bottom: 0,
                  backgroundColor: 'background.newneutral',
                  zIndex: 1,
                  borderTop: '2px solid #ccc', // Top border
                  borderBottom: '2px solid #ccc', // Bottom border
                }}
              >
                <TableRow
                  height={denseHeight}
                  sx={{
                    backgroundColor: 'background.newneutral',
                    border: '2px solid #ccc', // Border for the entire row
                  }}
                >
                  {/* <TableCell width="15%" /> */}
                  <TableCell
                    width="15%"
                    sx={{
                      position: 'sticky',
                      left: 0,
                      backdropFilter: 'blur(100px)',
                      borderRight: '2px solid #ccc', // Right border for the cell
                      borderLeft: '2px solid #ccc', // Left border for the cell
                    }}
                  >
                    <FormControlLabel
                      control={<Switch checked={dense} onChange={onChangeDense} />}
                      label="Dense"
                      sx={{ pl: 2, top: 0 }}
                    />
                  </TableCell>
                  {userRole === 'broker' && <TableCell width="15%" colSpan={3} sx={{ border: '2px solid #ccc' }} />}
                  {userRole === 'admin' ? (
                    <>
                      <TableCell align="left" sx={{ border: '2px solid #ccc' }}>
                        <ThreeValueDisplay
                          primaryValue={fNumber(totals?.yourGrossPl || '0.00')}
                          secondaryValue={fNumber(totals?.superAdminGrossPl || '0.00')}
                          thirdValue={fNumber(totals?.grossPl || '0.00')}
                          primaryColor={fColorNumber(totals?.yourGrossPl || 0)}
                        />
                      </TableCell>

                      <TableCell align="left" sx={{ border: '2px solid #ccc' }}>
                        <ThreeValueDisplay
                          primaryValue={fNumber(totals?.yourActivePl || '0.00')}
                          secondaryValue={fNumber(totals?.superAdminActivePl || '0.00')}
                          thirdValue={fNumber(totals?.activeProfitLoss || '0.00')}
                          primaryColor={fColorNumber(totals?.yourActivePl || 0)}
                        />
                      </TableCell>

                      <TableCell align="left" sx={{ border: '2px solid #ccc' }}>
                        <ThreeValueDisplay
                          primaryValue={fNumber(totals?.yourProfitLoss || '0.00')}
                          secondaryValue={fNumber(totals?.superAdminProfit || '0.00')}
                          thirdValue={fNumber(totals?.totalProfit || '0.00')}
                          primaryColor={fColorNumber(totals?.yourProfitLoss || 0)}
                        />
                      </TableCell>

                      <TableCell align="left" sx={{ border: '2px solid #ccc' }}>
                        <ThreeValueDisplay
                          primaryValue={fNumber(totals?.yourBrokerage || '0.00')}
                          secondaryValue={fNumber(totals?.superAdminBrokerage || '0.00')}
                          thirdValue={fNumber(totals?.totalBrokerage || '0.00')}
                        />
                      </TableCell>

                      <TableCell align="left" style={{ width: '15%' }} sx={{ border: '2px solid #ccc' }}>
                        <Typography variant="h7">{fNumber(totals?.refundBrokerage?.toFixed(2) || '0.00')}</Typography>
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell width="15%" align="left" sx={{ border: '2px solid #ccc' }}>
                        <TwoValueDisplay
                          primaryValue={fNumber(totals?.yourGrossPl || '0.00')}
                          secondaryValue={fNumber(totals?.grossPl || '0.00')}
                          primaryColor={fColorNumber(totals?.yourGrossPl || 0)}
                        />
                      </TableCell>

                      <TableCell width="15%" align="left" sx={{ border: '2px solid #ccc' }}>
                        <TwoValueDisplay
                          primaryValue={fNumber(totals?.yourActivePl || '0.00')}
                          secondaryValue={fNumber(totals?.activeProfitLoss || '0.00')}
                          primaryColor={fColorNumber(totals?.yourActivePl || 0)}
                        />
                      </TableCell>

                      <TableCell width="15%" align="left" sx={{ border: '2px solid #ccc' }}>
                        <TwoValueDisplay
                          primaryValue={fNumber(totals?.yourProfitLoss || '0.00')}
                          secondaryValue={fNumber(totals?.totalProfit || '0.00')}
                          primaryColor={fColorNumber(totals?.yourProfitLoss || 0)}
                        />
                      </TableCell>

                      <TableCell width="15%" align="left" sx={{ border: '2px solid #ccc' }}>
                        <TwoValueDisplay
                          primaryValue={fNumber(totals?.yourBrokerage || '0.00')}
                          secondaryValue={fNumber(totals?.totalBrokerage || '0.00')}
                        />
                      </TableCell>

                      <TableCell width="15%" align="left" sx={{ border: '2px solid #ccc' }}>
                        <TwoValueDisplay
                          primaryValue={fNumber(0)}
                          secondaryValue={fNumber(totals?.refundBrokerage || '0.00')}
                        />
                      </TableCell>
                    </>
                  )}
                  {userRole === 'broker' && <TableCell sx={{ border: '2px solid #ccc' }} />}
                  {userRole !== 'broker' && <TableCell width="15%" align="right" sx={{ border: '2px solid #ccc' }} />}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          {/* </Scrollbar> */}

          <Box sx={{ position: 'relative' }}>
            {/* <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={ClientsData?.userbalanceSummary?.totalDocs || 0}
              rowsPerPage={rowsPerPage}
              page={currentPage - 1}
              onPageChange={(e) => {
                if (e.target.dataset.testid === 'KeyboardArrowRightIcon') {
                  setCurrentPage(ClientsData?.scriptwiseTrades?.page + 1);
                  dispatch(
                    getUserBalanceSummary({
                      userId,
                      pageSize: rowsPerPage,
                      pageNumber: ClientsData?.userbalanceSummary?.page + 1,
                      searchWord: filterName,
                      filterName,
                      sortOrder: order,
                      sortBy: orderBy,
                    })
                  );
                } else {
                  setCurrentPage(ClientsData?.scriptwiseTrades?.page - 1);
                  dispatch(
                    getUserBalanceSummary({
                      userId,
                      pageSize: rowsPerPage,
                      pageNumber: ClientsData?.userbalanceSummary?.page - 1,
                      searchWord: filterName,
                      filterName,
                      sortOrder: order,
                      sortBy: orderBy,
                    })
                  );
                }
              }}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setCurrentPage(1);
                dispatch(
                  getUserBalanceSummary({
                    userId,
                    pageSize: parseInt(event.target.value, 10),
                    pageNumber: 1,
                    searchWord: filterName,
                    filterName,
                    sortOrder: order,
                    sortBy: orderBy,
                  })
                );
              }}
            /> */}
            {/* <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{
                pl: 2,
                py: 1.5,
                top: 0,
                position: { md: 'absolute' },
              }}
            /> */}
          </Box>
        </Card>
        {Object.keys(balanceDetail).length > 0 && (
          // <FinancialSummary data={balanceDetail} title="Total Financial Summary" role={user.role.rolename} />
          <SummaryView summary={balanceDetail} onActiveBuySellClick={handleActiveBuySellCLick} />
        )}
      </Container>
    </Page>
  );
}

function applySortFilter({ tableData }) {
  // Assuming the backend already handles sorting/filtering
  return tableData;
}

// function emptyRows(page, rowsPerPage, length) {
//   if (length === 0) {
//     return 0;
//   }
//   return Math.max(0, (1 + page) * rowsPerPage - length);
// }
